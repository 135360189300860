import { createSlice } from '@reduxjs/toolkit'

const userSlice = createSlice({
  name: 'userInfo',
  initialState: null,
  reducers: {
    setUser: (state, action) => action.payload,
    clearUser: () => null
  }
})

export const { setUser, clearUser } = userSlice.actions
export default userSlice.reducer
