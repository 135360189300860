// ** React Imports
import { Fragment, SyntheticEvent, useState } from 'react'

// ** Next Import
import { useRouter } from 'next/router'

// ** MUI Imports
import Badge from '@mui/material/Badge'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

// ** Icons Imports
import { Typography } from '@mui/material'
import { useTranslation } from 'next-i18next'
import ReactCountryFlag from 'react-country-flag'
import { User } from 'src/store/api/coreApi'

interface IMDLanguageSelectorDropdown {
  userInfo?: User
}

const LanguageSelectorDropdown = ({}: IMDLanguageSelectorDropdown) => {
  // ** States
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const { i18n } = useTranslation('footer')

  // ** Hooks
  const router = useRouter()

  const handleDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handleDropdownClose = (url?: string) => {
    if (url) {
      router.push(url)
    }
    setAnchorEl(null)
  }

  const onLanguageChange = (lang: string): void => {
    router.push(
      {
        pathname: router.pathname,
        query: router.query
      },
      undefined,
      { locale: lang }
    )
  }

  return (
    <Fragment>
      <Badge
        onClick={handleDropdownOpen}
        sx={{ ml: 2, cursor: 'pointer' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <ReactCountryFlag
          countryCode={i18n.language === 'en' ? 'GB' : i18n.language}
          svg
          style={{
            fontSize: '2em',
            lineHeight: '2em'
          }}
        />
      </Badge>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleDropdownClose()}
        sx={{ '& .MuiMenu-paper': { width: 150, marginTop: 4 } }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem sx={{ py: 2 }} onClick={() => onLanguageChange('en')}>
          <ReactCountryFlag
            countryCode='GB'
            svg
            style={{
              fontSize: '2em',
              lineHeight: '2em'
            }}
          />
          <Typography variant='button' sx={{ paddingLeft: 2 }}>
            English
          </Typography>
        </MenuItem>
        <MenuItem sx={{ py: 2 }} onClick={() => onLanguageChange('de')}>
          <ReactCountryFlag
            countryCode='de'
            svg
            style={{
              fontSize: '2em',
              lineHeight: '2em'
            }}
          />
          <Typography variant='button' sx={{ paddingLeft: 2 }}>
            Deutsche
          </Typography>
        </MenuItem>
        <MenuItem sx={{ py: 2 }} onClick={() => onLanguageChange('nl')}>
          <ReactCountryFlag
            countryCode='nl'
            svg
            style={{
              fontSize: '2em',
              lineHeight: '2em'
            }}
          />
          <Typography variant='button' sx={{ paddingLeft: 2 }}>
            Dutch
          </Typography>
        </MenuItem>
        <MenuItem sx={{ py: 2 }} onClick={() => onLanguageChange('fr')}>
          <ReactCountryFlag
            countryCode='fr'
            svg
            style={{
              fontSize: '2em',
              lineHeight: '2em'
            }}
          />
          <Typography variant='button' sx={{ paddingLeft: 2 }}>
            French
          </Typography>
        </MenuItem>
      </Menu>
    </Fragment>
  )
}

export default LanguageSelectorDropdown
