import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { api } from './api/api'
import { rtkQueryErrorLogger } from './api/middleware'
import authInterceptor from './auth/auth.inceptor'
import authReducer from './auth/authSlice'
import userSlice from './globals/reducers'

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    auth: authReducer,
    user: userSlice
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(api.middleware).concat(authInterceptor).concat(rtkQueryErrorLogger)
})

setupListeners(store.dispatch)
