import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit'
import Router from 'next/router'

const authInterceptor: Middleware = () => next => action => {
  if (isRejectedWithValue(action) && action.payload.status === 401) {
    Router.push('/login')
  }

  return next(action)
}

export default authInterceptor
