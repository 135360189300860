import { Autocomplete, TextField } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useTranslation } from 'next-i18next'
import { useEffect, useState } from 'react'
import { Client, useCoreUsersMaskClientMutation, useLazyCoreClientsListQuery } from 'src/store/api/coreApi'
import { CLIENT_ID } from 'src/store/auth/authAction'
import * as Sentry from '@sentry/nextjs'

interface IMDMaskUserDialog {
  open: boolean
  onClose: () => void
  onMaskUserConfirm: (clientObj: Client) => void
}

const MDCMaskUserDialog: React.FC<IMDMaskUserDialog> = ({ open, onClose, onMaskUserConfirm }) => {
  const [maskUser, { data: maskResp }] = useCoreUsersMaskClientMutation()
  const [autocompleteSearch, setAutocompleteSearch] = useState<string>('')
  const [autocompleteOptions, setAutocompleteOptions] = useState<Client[]>([])
  const [getClient, { data: clientData }] = useLazyCoreClientsListQuery()
  const { t } = useTranslation()

  const [targetUser, setTargetUser] = useState<Client | undefined>(undefined)

  const onMaskUser = () => {
    try {
      if (targetUser) {
        maskUser({ maskUserRequest: { client_id: (targetUser as any).id as string, auth_id: CLIENT_ID as string } })
      }
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
    }
  }

  useEffect(() => {
    if (maskResp && typeof window !== 'undefined') {
      localStorage.setItem('userTokenMask', maskResp.access_token)
      onMaskUserConfirm(targetUser as Client)
      onClose()
    }
  }, [maskResp, onClose, onMaskUserConfirm, targetUser])

  useEffect(() => {
    if (open) {
      getClient({})
    }
  }, [getClient, open])

  useEffect(() => {
    if (autocompleteSearch.length > 0) {
      getClient({ nameIcontains: autocompleteSearch })
    }
  }, [autocompleteSearch])

  useEffect(() => {
    if ((clientData?.count as number) > 0) {
      setAutocompleteOptions(clientData?.results as Client[])
    }
  }, [clientData])

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth={'sm'}
        onClose={onClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{t('MaskClient')}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <Autocomplete
              options={autocompleteOptions}
              onInputChange={(event: any, newInputValue: any) => {
                setAutocompleteSearch(newInputValue)
              }}
              onChange={(event: any, newValue: any) => {
                setTargetUser(newValue as Client)
              }}
              getOptionLabel={(option: any) => `${option.name}`}
              renderInput={(params: any) => <TextField {...params} variant='outlined' label={t('Client')} />}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t('Close')}</Button>
          <Button onClick={() => onMaskUser()} autoFocus>
            {t('Mask')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default MDCMaskUserDialog
