import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.NEXT_PUBLIC_APP_API_URL}api/`,
    prepareHeaders: async headers => {
      let token: string | undefined
      if (typeof window !== 'undefined') {
        const maskToken = localStorage.getItem('userTokenMask') as string
        if (maskToken) {
          token = maskToken
        } else {
          token = localStorage.getItem('userToken') as string
        }
        if (token) {
          headers.set('Authorization', `Bearer ${token}`)
        }
      }

      return headers
    },
    credentials: 'include'
  }),
  endpoints: builder => ({
    getQuery: builder.query<any, any>({
      query: ({ appName, sectionName }) => ({
        url: `${appName}/${sectionName}/`,
        method: 'GET'
      })
    }),
    getQueryById: builder.query<any, any>({
      query: ({ appName, objId }) => `${appName}/${objId}/`
    }),
    getQuerySchema: builder.query<any, any>({
      query: ({ appName, sectionName }) => ({
        url: `${appName}/${sectionName}/`,
        method: 'OPTIONS'
      })
    }),
    UpdateQuery: builder.mutation<any, any>({
      query: ({ appName, objId, data }) => ({
        url: `${appName}/${objId}/`,
        method: 'PUT',
        body: data
      })
    })
  })
})

export const {
  useGetQueryByIdQuery,
  useGetQueryQuery,
  useGetQuerySchemaQuery,
  useLazyGetQueryByIdQuery,
  useLazyGetQueryQuery,
  useLazyGetQuerySchemaQuery
} = api
