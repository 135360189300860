// ** MUI Imports
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';
import getConfig from 'next/config';


const FooterContent = () => {
  const { publicRuntimeConfig } = getConfig();
  const { t } = useTranslation()

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between' }}>
      <Typography sx={{ mr: 2 }}>
        {`© ${new Date().getFullYear()}, ${t('Made with')} `}
        <Box component='span' sx={{ color: 'error.main' }}>
          ❤️
        </Box>
        {` by `}
        <Link target='_blank' href='https://moodus.com/'>
          Moodus
        </Link>
      </Typography>
      <Typography variant='caption'>Version: {publicRuntimeConfig?.version}</Typography>
    </Box>
  )
}

export default FooterContent
