// ** MUI Imports
import { Button, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { Theme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import * as Sentry from '@sentry/nextjs'
import Menu from 'mdi-material-ui/Menu'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MDCMaskUserDialog from 'src/@core/components/MDMaskUserDialog'
import { Settings } from 'src/@core/context/settingsContext'
import LanguageSelectorDropdown from 'src/@core/layouts/components/shared-components/LanguageSelectorDropdown'
import ModeToggler from 'src/@core/layouts/components/shared-components/ModeToggler'
import UserDropdown from 'src/@core/layouts/components/shared-components/UserDropdown'
import { Client, useLazyCoreUsersGetUserInfoQuery, User } from 'src/store/api/coreApi'
import { setUser } from 'src/store/globals/reducers'

interface Props {
  hidden: boolean
  settings: Settings
  toggleNavVisibility: () => void
  saveSettings: (values: Settings) => void
}

const AppBarContent = (props: Props) => {
  // ** Props
  const { hidden, settings, saveSettings, toggleNavVisibility } = props
  const [openMaskDialog, setOpenMaskDialog] = useState(false)
  const [getUser, { data: userInfoResp }] = useLazyCoreUsersGetUserInfoQuery()
  const [client, setClient] = useState<Client | undefined>(undefined)
  const userInfo = useSelector((state: { user: User }) => state.user)
  const [maskActive, setMaskActive] = useState<boolean>(false)
  const dispatch = useDispatch()
  const router = useRouter()
  const { t } = useTranslation()

  const [maskToken, setMaskToken] = useState<string>("");


  // ** Hook
  const hiddenSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  useEffect(() => {
    setMaskToken(localStorage.getItem('userTokenMask') || "")

    if (userInfo?.client) {
      setClient(userInfo.client)
    }
  }, [userInfo])

  const onUnmaskToken = () => {
    try {
      if (typeof window !== 'undefined') {
        localStorage.removeItem('userTokenMask')
        setClient(undefined)
        getUser({})
        router.reload()
      }
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
    }
  }

  const onMaskUser = (clientObj: Client) => {
    if (!maskActive) {
      setClient(clientObj)
      setMaskActive(true)
      getUser({})
      router.reload()
    }
  }

  useEffect(() => {
    if (userInfoResp) {
      dispatch(setUser(userInfoResp))
    }
  }, [userInfoResp])

  return (
    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <MDCMaskUserDialog
        open={openMaskDialog}
        onClose={function (): void {
          setOpenMaskDialog(false)
        }}
        onMaskUserConfirm={clientObj => onMaskUser(clientObj)}
      />
      <Box className='actions-left' sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
        {hidden ? (
          <IconButton
            color='inherit'
            onClick={toggleNavVisibility}
            sx={{ ml: -2.75, ...(hiddenSm ? {} : { mr: 3.5 }) }}
          >
            <Menu />
          </IconButton>
        ) : null}
      </Box>
      <Box className='actions-right' sx={{ display: 'flex', alignItems: 'center' }}>
        {client && maskToken ? (
          <Button
            size='small'
            color='error'
            onClick={() => {
              onUnmaskToken()
            }}
          >
            Masked as {client.name} (UnMask User)
          </Button>
        ) : client && !maskToken ? <Typography sx={{ color: "#9155FD" }}>HELLO {client?.name?.toUpperCase() || 'USER'}</Typography> : (
          userInfo?.is_staff && (
            <Button
              size='small'
              onClick={() => {
                setOpenMaskDialog(true)
              }}
            >
              {t('Mask User')}
            </Button>
          )
        )}
        <ModeToggler settings={settings} saveSettings={saveSettings} />
        <UserDropdown userInfo={userInfo} onUnmaskToken={onUnmaskToken} />
        <LanguageSelectorDropdown userInfo={userInfo} />
      </Box>
    </Box>
  )
}

export default AppBarContent
