import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const BASE_URL = process.env.NEXT_PUBLIC_APP_API_URL
export const CLIENT_ID = process.env.NEXT_PUBLIC_CLIENT_ID
const CLIENT_SECRET = process.env.NEXT_PUBLIC_CLIENT_SECRET

interface userLogin {
  username: string
  password: string
}

export const userLogin = createAsyncThunk(
  'auth/login',
  async ({ username, password }: userLogin, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      const { data } = await axios.post(
        `${BASE_URL}auth/token/`,
        {
          username,
          password,
          grant_type: 'password',
          client_id: CLIENT_ID,
          client_secret: CLIENT_SECRET
        },
        config
      )

      if (typeof window !== 'undefined') {
        localStorage.removeItem('userTokenMask')
        localStorage.setItem('userToken', data.access_token)
      }

      return data
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)
