import { createSlice } from '@reduxjs/toolkit'
import { userLogin } from './authAction'

// initialize userToken from local storage
let userToken: string | null = null
if (typeof window !== 'undefined') {
  userToken = localStorage.getItem('userToken') ? localStorage.getItem('userToken') : null
}

const initialState = {
  loading: false,
  userInfo: null,
  userToken,
  error: null,
  success: false
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(userLogin.pending, state => {
        state.loading = true
        state.success = false
        state.error = null
      })
      .addCase(userLogin.fulfilled, state => {
        state.loading = false
        state.success = true
        state.error = null
      })
      .addCase(userLogin.rejected, state => {
        state.loading = false
        state.success = false
        state.error = null
      })
  }
})
export default authSlice.reducer
