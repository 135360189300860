import type { EmotionCache } from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import { GoogleOAuthProvider } from '@react-oauth/google'
import type { NextPage } from 'next'
import { appWithTranslation } from 'next-i18next'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { Router } from 'next/router'
import NProgress from 'nprogress'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Provider } from 'react-redux'
import { SettingsConsumer, SettingsProvider } from 'src/@core/context/settingsContext'
import ThemeComponent from 'src/@core/theme/ThemeComponent'
import { createEmotionCache } from 'src/@core/utils/create-emotion-cache'
import themeConfig from 'src/configs/themeConfig'
import UserLayout from 'src/layouts/UserLayout'
import '../../styles/globals.css'
import { store } from '../store'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

type ExtendedAppProps = AppProps & {
  Component: NextPage
  emotionCache: EmotionCache
}

const clientSideEmotionCache = createEmotionCache()

// ** Pace Loader
if (themeConfig.routingLoader) {
  Router.events.on('routeChangeStart', () => {
    NProgress.start()
  })
  Router.events.on('routeChangeError', () => {
    NProgress.done()
  })
  Router.events.on('routeChangeComplete', () => {
    NProgress.done()
  })
}

// ** Configure JSS & ClassName
const App = (props: ExtendedAppProps) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props

  // Variables
  const getLayout = Component.getLayout ?? (page => <UserLayout>{page}</UserLayout>)

  return (
    <GoogleOAuthProvider clientId='331448248275-7demd4cudrqulepsflf967ihsqkt6dlt.apps.googleusercontent.com'>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Provider store={store}>
          <CacheProvider value={emotionCache}>
            <Head>
              <title>Moodus Dashboard</title>
              <meta name='description' content={'Moodus unified dashboard'} />
              <meta name='keywords' content='Moodus,Give,GivePlus' />
              <meta name='viewport' content='initial-scale=1, width=device-width' />
            </Head>

            <SettingsProvider>
              <SettingsConsumer>
                {({ settings }) => {
                  return <ThemeComponent settings={settings}>{getLayout(<Component {...pageProps} />)}</ThemeComponent>
                }}
              </SettingsConsumer>
            </SettingsProvider>
          </CacheProvider>
        </Provider>
      </LocalizationProvider>
    </GoogleOAuthProvider>
  )
}
export default appWithTranslation(App)
